import { defineStore } from "pinia";
import Vue from "vue";
import { useUserStore } from "@/stores/userStore.js";

export const useArchiveStore = defineStore("archives", {
  state: () => ({
    folderid: 0,
    selected_archives: [],
    sort_options: { "sortBy": ["archived_year"], "sortDesc": [true, false] },
    data: [],
    loading: false,
    pagesize: 10,
    pageindex: 1,
    totalrecords: 0,
    year:0,
    q: "",
    verify_tyle_list: [
      { id: -1, name: "未审核" },
      { id: 0, name: "全部" },
      { id: 1, name: "已审核" },
    ],
    verify_type: 0,
  }),
  getters: {
    get_total_pages: (state) => {
      if (state.totalrecords) {
        return Math.ceil(state.totalrecords / state.pagesize);
      }
      return 0;
    },
    active_archive: (state) => {
      if (state.selected_archives && state.selected_archives.length) {
        return state.selected_archives[0];
      }
      return null;
    },
    archive_permission: (state) => {
      const userStore = useUserStore();
      if (userStore.isUserInRoles([1])) return true;
      // if (userStore.isUserInRoles([2])) {
      //   if (
      //     state.active_archive &&
      //     !state.active_archive.verified &&
      //     state.active_archive.created_by == userStore.profile.id
      //   ) {
      //     return true;
      //   }
      // }
      return false;
    },
    view_document_permission: (state) => {
      const userStore = useUserStore();
      if (userStore.isUserInRoles([1])) return true;
      if (userStore.isUserInRoles([5])) {
        // if (
        //   state.active_archive &&
        //   state.active_archive.created_by == userStore.profile.id
        // ) {
        //   return true;
        // }
        return true;
      }
      return false;
    },
  },
  actions: {
    loadData(clear, old) {
      this.loading = true;
      if (clear) {
        this.selected_archives = [];
      }
      if (this.folderid || this.verify_type != 0) {
        Vue.hc
          .req()
          .post(
            `archives/search?year=${this.year}&verified=${this.verify_type || 0}&fid=${
              this.folderid || 0
            }&pagesize=${this.pagesize}&pageindex=${this.pageindex}&q=${
              this.q || ""
            }`,{
              sortBy: this.sort_options?.sortBy || [],
              sortDesc: this.sort_options?.sortDesc || []
            }
          )
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.data = x.Data.Payload;
              this.totalrecords = x.Data.Total;
              if (old) {
                for (var i = 0; i < this.data.length; i++) {
                  if (this.data[i].id == old.id) {
                    this.selected_archives = [];
                    this.selected_archives = [this.data[i]];
                  }
                }
              }
              this.loading = false;
            },
            (x) => {
              this.loading = false;
            }
          );
      } else {
        this.$reset();
      }
    },
    createOrUpdate(vm) {
      this.loading = true;
      if (vm.id) {
        Vue.hc
          .req()
          .put(`archives`, vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData(true, vm);
            },
            (x) => {
              this.loading = false;
            }
          );
      } else {
        Vue.hc
          .req()
          .post(`archives`, vm)
          .go({
            lock: true,
            toastError: true,
            toastSuccess: "",
          })
          .subscribe(
            (x) => {
              this.loadData();
            },
            (x) => {
              this.loading = false;
            }
          );
      }
    },
    delete() {
      this.loading = true;
      Vue.hc
        .req()
        .delete(`archives/${this.active_archive.id}`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loadData(true);
          },
          (x) => {
            this.loading = false;
          }
        );
    },
    import(file, fid, aid) {
      var fe = new FormData();
      fe.append("aid", aid || 0);
      fe.append("fid", fid || 0);
      fe.append("file", file);
      return Vue.hc.req().post(`archivedocuments/import`, fe).go({
        lock: true,
        toastError: true,
        toastSuccess: "",
      });
    },
    importZip(file,aid){
      var fe = new FormData();
      fe.append("aid", aid || 0);
      fe.append("file", file);
      return Vue.hc.req().post(`archivedocuments/importzip`, fe).go({
        lock: true,
        toastError: true,
        toastSuccess: "",
      });
    },
    switchVerify(id) {
      this.loading = true;
      Vue.hc
        .req()
        .put(`archives/verify?id=${id}`, {})
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.loadData(false);
          },
          (x) => {
            this.loading = false;
          }
        );
    },
  },
});
