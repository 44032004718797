<template>
  <div>
    <v-card
      outlined
      :class="{'fullscreen':fullscreen}"
    >
      <v-toolbar
        color="blue-grey  lighten-5"
        dense
      >
        <v-toolbar-title class="text-subtitle-1">案卷</v-toolbar-title>
        <v-spacer></v-spacer>
        <div style="width: 150px">
          <v-select
            dense
            hide-details="true"
            item-text="name"
            item-value="value"
            :items="yearitems"
            v-model="year"
            label="归档年度"
            style="margin-top:5px;"
            @change="search()"
            class="ft1rem"
          ></v-select>
        </div>
        <v-spacer></v-spacer>
        <div style="width: 300px">
          <v-text-field
            label="搜索 (档号,题名,文件编号,文件题名)"
            v-model="q"
            dense
            flat
            class="mr-10 ft1rem"
            hide-details
            :append-icon="'search'"
            @keyup.enter.stop="search()"
            @click:append="search()"
            style="margin-top:5px;"
          ></v-text-field>
        </div>
        <div
          v-if="VerifyFilter"
          style="width: 200px"
        >
          <v-select
            dense
            hide-details="true"
            item-text="name"
            item-value="id"
            :items="verify_tyle_list"
            v-model="verify_type"
            label="审核状态"
          ></v-select>
        </div>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              icon
              small
              v-on="on"
              @click="switchFullscreen"
            >
              <v-icon>{{fullscreen?"fullscreen_exit":"fullscreen"}}</v-icon>
            </v-btn>
          </template>
          <span>{{fullscreen?"退出全屏":"全屏模式"}}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              icon
              small
              v-on="on"
              @click="openRequestDialog"
              :disabled="!selected_folder"
            >
              <v-icon>move_to_inbox</v-icon>
            </v-btn>
          </template>
          <span>入档请求</span>
        </v-tooltip>
         <v-tooltip
          bottom
          v-if="canimport"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              @click="openImportZipDialog"
              :disabled="!selected_folder || !archive_permission"
            >
              <v-icon>folder_zip</v-icon>
            </v-btn>
          </template>
          <span>打包导入文件</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="canimport"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              @click="openImportDialog"
              :disabled="!selected_folder || !archive_permission"
            >
              <v-icon>save_alt</v-icon>
            </v-btn>
          </template>
          <span>批量导入案卷</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="canupload"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              @click="openFileDialog"
              :disabled="!archive_upload || !archive_permission"
            >
              <v-icon>cloud_upload</v-icon>
            </v-btn>
          </template>
          <span>上传文件</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="cancreate"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              icon
              v-on="on"
              @click="openAddDialog"
              :disabled="!selected_folder"
            >
              <v-icon>add_circle</v-icon>
            </v-btn>
          </template>
          <span>新建案卷</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="canedit"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="green"
              small
              icon
              v-on="on"
              @click="openEditDialog"
              :disabled="!active_archive || !archive_permission"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>编辑案卷</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="candelete"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="error"
              small
              icon
              v-on="on"
              @click="deleteArchive"
              :disabled="!active_archive || !archive_permission"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>删除案卷</span>
        </v-tooltip>
      </v-toolbar>
      <c-archive-list
        ref="list"
        :vh="vh"
        :verify="verify"
        :fullscreen="fullscreen"
      ></c-archive-list>
    </v-card>
    <archive-edit-dialog ref="_archive_dialog"></archive-edit-dialog>
    <file-upload-dialog ref="_file_dialog"></file-upload-dialog>
    <archive-request-create-dialog ref="_archive_request_dialog"></archive-request-create-dialog>
    <input
      type="file"
      ref="_file_input"
      style="display: none"
      accept=".xls,.xlsx"
      @change="onFileChange"
    />
    <input
      type="file"
      ref="_zip_file_input"
      style="display: none"
      accept=".zip"
      @change="onZipFileChange"
    />
  </div>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useFolderStore } from "@/stores/folderStore.js";
import { useArchiveStore } from "@/stores/archiveStore.js";
import { useRecordStore } from "@/stores/recordStore.js";
import { useDocumentStore } from "@/stores/documentStore.js";
export default {
  name: "CArchives",
  props: [
    "vh",
    "cancreate",
    "canedit",
    "candelete",
    "canupload",
    "canimport",
    "verify",
    "VerifyFilter",
  ],
  data() {
    return {
      fullscreen: false,
      yearitems: [],
      dialogs: {
        edit: {
          type: 0,
          open: false,
          archive: {},
          rules: [],
        },
      },
    };
  },
  computed: {
    ...mapState(useUserStore, ["profile"]),
    ...mapState(useFolderStore, ["selected_folder"]),
    ...mapState(useArchiveStore, [
      "active_archive",
      "verify_tyle_list",
      "archive_permission",
    ]),
    ...mapState(useRecordStore, {
      records: "data",
    }),
    ...mapState(useDocumentStore, {
      documents: "data",
    }),
    ...mapWritableState(useArchiveStore, ["q", "verify_type", "year"]),
    archive_upload() {
      if (!this.selected_folder || !this.active_archive) return false;
      if (this.records && this.records.length) return false;
      return true;
    },
  },
  mounted() {
    this.yearitems.push({
      name: "所有",
      value: 0,
    });
    const currentYear = new Date().getUTCFullYear();
    for (var y = currentYear; y >= 1949; y--) {
      this.yearitems.push({
        name: y + "",
        value: y,
      });
    }
  },
  methods: {
    inrole(arr) {
      const userStore = useUserStore();
      return userStore.isUserInRoles(arr);
    },
    openAddDialog() {
      const folderStore = useFolderStore();
      const folder = folderStore.getSelectedNodeCopy();
      var config = {
        type: 0,
        archive: {
          rootno: folderStore.get_root_folder_name,
          customfield: "",
          folderid: folder.id,
          archived_date: this.$moment().format("YYYY-MM-DD"),
          created_date: this.$moment().format("YYYY-MM-DD"),
          verified_date: this.$moment().format("YYYY-MM-DD"),
          charger: this.profile.name,
          createby: this.profile.name,
          verifiedby: this.profile.name,
        },
        rules: folder.activerules,
      };
      this.$refs._archive_dialog.openDialog(config);
    },
    openEditDialog() {
      const folderStore = useFolderStore();
      const folder = folderStore.getSelectedNodeCopy();
      var config = {
        type: 1,
        archive: {
          ...this.active_archive,
          rootno: folderStore.get_root_folder_name,
          customfield: "",
        },
        rules: folder.activerules,
      };
      this.$refs._archive_dialog.openDialog(config);
    },
    deleteArchive() {
      this.$confirm(
        "确定要删除这个案卷吗? 该案卷及其包含的文件都将被删除"
      ).then((x) => {
        if (x) {
          const store = useArchiveStore();
          store.delete();
        }
      });
    },
    search() {
      const store = useArchiveStore();
      store.pageindex = 1;
      store.loadData(true);
    },
    openFileDialog() {
      this.$refs._file_dialog.openDialog(this.active_archive.id, 0);
    },
    openImportDialog() {
      this.$refs._file_input.click();
    },
    openImportZipDialog() {
      this.$refs._zip_file_input.click();
    },
    openRequestDialog() {
      const folderStore = useFolderStore();
      const folder = folderStore.getSelectedNodeCopy();
      var config = {
        type: 0,
        archive: {
          rootno: folderStore.get_root_folder_name,
          customfield: "",
          folderid: folder.id,
          archived_date: this.$moment().format("YYYY-MM-DD"),
          created_date: this.$moment().format("YYYY-MM-DD"),
          verified_date: this.$moment().format("YYYY-MM-DD"),
          charger: this.profile.name,
          createby: this.profile.name,
          verifiedby: this.profile.name,
        },
        rules: folder.activerules,
      };
      this.$refs._archive_request_dialog.openDialog(config);
    },
    onFileChange() {
      var file = event.target.files;
      if (file && file.length > 0) {
        const store = useArchiveStore();
        const folderStore = useFolderStore();
        const recordStore = useRecordStore();
        const folder = folderStore.getSelectedNodeCopy();
        store.import(file[0], folder.id, 0).subscribe(
          (x) => {
            store.loadData();
            recordStore.loadData();
          },
          (x) => {}
        );
        event.target.value = "";
      }
    },
    onZipFileChange() {
      var file = event.target.files;
      if (file && file.length > 0) {
        const store = useArchiveStore();
        const docstore = useDocumentStore();
        store.importZip(file[0], this.active_archive.id).subscribe(
          (x) => {
            docstore.loadData();
          },
          (x) => {}
        );
        event.target.value = "";
      }
    },
    switchFullscreen() {
      const store = useArchiveStore();
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        store.pagesize = 0;
        store.loadData();
      } else {
        store.pagesize = 10;
        store.loadData();
      }
    },
  },
};
</script>
